import { gql } from '@apollo/client';

export const NEW_EARNED_EXP_EVENT = gql`
  subscription NewEarnedExpEvent {
    newEarnedExpEvent {
      id
      earnedBy {
        id
        exp
      }
      # increment all my teams with communityExp each time new earnedExpEvent for the current member
      expEvent {
        id
        communityExp
      }
    }
  }
`;

export const NEW_EXP_LEVEL = gql`
  subscription NewExpLevel {
    newExpLevel {
      id
      name
      iconPublicUrl
      customBadge {
        badgeId
        companyId
        minExp
      }
      nextBadge {
        id
        name
        customBadge {
          badgeId
          companyId
          minExp
        }
      }
    }
  }
`;
