import React, { createContext, FC, useEffect, useState } from 'react';

import {
  DateRange,
  GetPeoplesQuery,
  GetPeoplesQueryResult,
  NewEarnedExpEventSubscription,
  PeopleFilterField,
  PeopleInputOption,
  useGetPeoplesLazyQuery,
} from '../../graphql/generated/graphql';
import { NetworkStatus } from '@apollo/client';
import { formattedWithMyTimezone, PARAM_DATE_FORMAT_YYYY_MM_DD } from 'utils';
import { useFilterToString } from 'components/scenes/People/hooks';
import { NEW_EARNED_EXP_EVENT } from 'graphql/subscription/companyMember';

export interface IPeoplesContext {
  field: PeopleFilterField;
  filters: (string | PeopleInputOption)[];
  handleChangeField?: (field: PeopleFilterField) => void;
  handleChangeFilters?: (filters: (string | PeopleInputOption)[]) => void;
  companyMembers?: GetPeoplesQuery['companyMembers'];
  loading?: GetPeoplesQueryResult['loading'];
  fetchMore?: GetPeoplesQueryResult['fetchMore'];
  refetch?: GetPeoplesQueryResult['refetch'];
  isFetchMore?: boolean;
  total: number;
  dateRange?: DateRange;
  handleChangeDateRange?: (newDateRange: DateRange) => void;
}

export const PeoplesContext = createContext<IPeoplesContext>({
  field: PeopleFilterField.AllFields,
  filters: [],
  total: 0,
});

export const PeoplesProvider: FC = ({ children }) => {
  const [getPeopleQuery, { data, loading, fetchMore, refetch, networkStatus, subscribeToMore }] =
    useGetPeoplesLazyQuery({ notifyOnNetworkStatusChange: true });

  const [field, setField] = useState<PeopleFilterField>(PeopleFilterField.AllFields);
  const [filters, setFilters] = useState<(string | PeopleInputOption)[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>({});
  const filterToString = useFilterToString();

  const handleChangeFilters = (filters: (string | PeopleInputOption)[]) => {
    setFilters(filters);
    const filterInputs = filters.map((filter) => filterToString(filter));
    refetch({ field, filters: filterInputs });
  };

  const handleChangeDateRange = (newDateRange: DateRange) => {
    setDateRange(newDateRange);
    const safeStartDate = formattedWithMyTimezone(
      newDateRange.startDate,
      PARAM_DATE_FORMAT_YYYY_MM_DD,
    );
    const safeEndDate = formattedWithMyTimezone(newDateRange.endDate, PARAM_DATE_FORMAT_YYYY_MM_DD);
    refetch({ field, filters: [safeStartDate, safeEndDate] });
  };

  const handleChangeField = (field: PeopleFilterField) => {
    setField(field);

    // from another field to birthday
    if (field === PeopleFilterField.Birthday && filters.length) {
      setFilters([]);
      refetch({ field, filters: [] });
      return;
    }

    // from birthday to another field
    if (dateRange.startDate && dateRange.endDate) {
      setDateRange({});
      refetch({ field, filters: [] });
      return;
    }

    // from another field to another field (not birthday)
    if (filters.length) refetch({ field });
  };

  useEffect(() => {
    getPeopleQuery({ variables: { field, filters: [] } });

    subscribeToMore<NewEarnedExpEventSubscription>({
      document: NEW_EARNED_EXP_EVENT,
      updateQuery: (prev, { subscriptionData }) => {
        // if no subscription data or member is not in list, return previous data
        const isCompanyMember = prev.companyMembers?.some(
          (companyMember) =>
            companyMember?.id === subscriptionData.data.newEarnedExpEvent?.earnedBy?.id,
        );
        if (!isCompanyMember) return prev;

        // else return member with new exp value
        return {
          ...prev,
          companyMembers: prev.companyMembers.map((companyMember) => {
            if (companyMember.id === subscriptionData.data.newEarnedExpEvent?.earnedBy?.id) {
              return {
                ...companyMember,
                exp: subscriptionData.data.newEarnedExpEvent?.earnedBy?.exp,
              };
            }

            return companyMember;
          }),
        };
      },
    });
  }, []);

  return (
    <PeoplesContext.Provider
      value={{
        field,
        filters,
        handleChangeField,
        handleChangeFilters,
        companyMembers: data?.companyMembers,
        loading,
        fetchMore,
        refetch,
        isFetchMore: networkStatus === NetworkStatus.fetchMore,
        total: data?.meta?.count ?? 0,
        dateRange,
        handleChangeDateRange,
      }}
    >
      {children}
    </PeoplesContext.Provider>
  );
};

export default PeoplesContext;
