const error: Record<string, string> = {
  'error.company.slug.not.found': 'Company slug not found',
  'something.went.wrong': "Quelque chose s'est mal passé",
  'error.create.idea': "Erreur lors de la création d'une idée",
  'error.signup': "Erreur lors de l'inscription",
  'error.community.update': "Une erreur s'est produite lors de la mise à jour de l'équipe",
  'error.community.create': "Une erreur s'est produite lors de la création de l'équipe",
  'error.community.delete': "Une erreur s'est produite lors de la suppréssion de l'équipe",
  'error.community.delete.user':
    "Une erreur s'est produite lors de la suppression d'un membre de l'équipe",
  'error.community.update.user.role':
    "Une erreur s'est produite lors de la mise à jour du rôle de membre de l'équipe",
  'error.create.indicator': "Une erreur s'est produite lors de la création de l'indicateur",
  'error.update.indicator': "Une erreur s'est produite lors de la mise à jour de l'indicateur",
  'error.delete.indicator': "Une erreur s'est produite lors de la suppréssion de l'indicateur",
  'error.action.delete': "Une erreur s'est produite lors de la suppréssion de l'action",
  'error.post.delete': "Une erreur s'est produite lors de la suppréssion du poste",
  'error.action.update': "Une erreur s'est produite lors de la mise à jour de l'action",
  'error.action.create': "Une erreur s'est produite lors de la création de l'action",
  'error.community.actions':
    "Une erreur s'est produite lors de l'obtention des actions de l'équipe",
  'error.user.not.found': "Une erreur s'est produite lors de l'obtention des utilisateurs",
  'error.create.project': "Une erreur s'est produite lors de la création du projet",
  'error.edit.project': "Une erreur s'est produite lors de la modification du projet",
  'error.convert.idea': "Une erreur s'est produite lors de la conversion de l'idée",
  'error.convert.post': "Une erreur s'est produite lors de la conversion du poste",
  'error.empty.community': "Vous n'avez pas d'équipe",
  'error.failed.to.fetch.company.products': "Echec de la récupération des produits de l'entreprise",
  'error.failed.to.create.order': 'Echec de la création de la commande',
  'error.failed.to.update.my.total.coins': 'Impossible de mettre à jour le nombre total de pièces',
  'error.failed.to.fetch.community.events':
    "Echec de la récupération des événements d'équipe pour l'équipe sélectionnée",
  'error.failed.to.fetch.team.event': "Echec de la récupération de l'événements de l'équipe",
  'error.failed.to.fetch.meeting.events': 'Impossible de récupérer les réunions',
  'error.must.have.community': 'Vous devez séléctionner une équipe',
  'error.must.have.expert': 'Vous devez séléctionner un expert',
  'error.must.have.responsible': 'Vous devez séléctionner un résponsable',
  'error.minimum.2022': 'Minimum 2022',
  'error.minimum.today': "Minimum aujourd'hui",
  'error.minimum.tomorrow': 'Minimum demain',
  'error.maximum.number.of.character':
    '{item} est trop long (Le maximum est de {maxNbrOfChars} characters)',
  'error.failed.to.get.problems.count':
    "Impossible d'obtenir le nombre de problèmes entre les équipes ",
  'error.failed.to.share.post':
    'Impossible de partager la publication ou la publication est déjà partagée avec cette équipe',
  'error.profile.not.found': 'Profil introuvable',
  'error.save.personal.information':
    "Une erreur s'est produite lors de l'enregistrement des informations personnelles",
  'error.delete.picture': "Une erreur s'est produite lors de la suppression de l'image",
  'error.delete.file': "Une erreur s'est produite lors de la suppression du fichier",
  'error.falided.to.create.counter.measure': 'Impossible de créer une contre-mesure ',
  'error.failed.to.create.counter.measure': 'Impossible de créer une contre-mesure ',
  'error.failed.to.update.counter.measure': 'Impossible de mettre à jour une contre-mesure ',
  'error.failed.to.delete.counter.measure': 'Impossible de supprimer une contre-mesure ',
  'error.failed.to.delete.goal': "Impossible de supprimer l'indicateur ",
  'error.failed.to.change.post.a3.status': 'Impossible de modifier le statut de la publication ',
  'error.failed.to.save.shared.horizon': 'Impossible de sauvegarder notre horizon partagé',
  'error.failed.to.update.shared.horizon': 'Impossible de modifier notre horizon partagé',
  'error.failed.to.delete.measure.file': 'Echec de la suppression du fichier de mesure',
  'error.failed.to.fetch.company.process': "Echec de la récupération des processus de l'entreprise",
  'error.incorrect.password': 'Mo de passe incorrect',
  'error.code.is.required': 'Le code est obligatoire',
  'error.code.is.incorrect': 'Le code est incorrect',
  'error.code.is.expired': 'Le code a expiré',
  'error.team.is.required': "L'équipe'est obligatoire",
  'error.supervisor.is.required': 'Le supérviseur est obligatoire',
  'user.change.supervisor.error': 'Echec de la mise à jour du superviseur',
  'error.current.company': "Une erreur s'est produite lors de la récupération de la société",
  'error.switch.company': "Une erreur s'est produite lors du changement de société",
  'error.file.is.larger.than.undred.mb': 'Le fichier est supérieur à 100 Mo',
  'error.file.invalid.type': 'Type de fichier non valide',
  'error.some.files.are.invalid': 'Certains fichiers ne sont pas valides',
  'error.file.too.many.files': 'Trop de fichiers',
  'error.file.is.larget.than.max.size': 'Le fichier est supérieur à {maxSize}Mb',
  'error.invalid.date': 'Date invalide',

  // Meeting
  'error.meeting.dialog.error.attendees': 'Veuillez spécifier au moins un participant',
  'error.failed.to.create.meeting': 'Echec de la création de la réunion',
  'error.failed.to.update.meeting': 'Echec de la mise à jour de la réunion',
  'error.failed.to.fetch.meeting': 'Echec de la récupération de la réunion ',
  'error.failed.to.fetch.registrant': 'Echec de la récupération des inscrits à la réunion ',
  'error.failed.to.accept.meeting': "Echec de l'acceptation de la réunion",
  'error.failed.to.deny.meeting': 'Echec du refus de la réunion',

  // User preference
  'error.failed.to.update.preference': 'Echec de la mise à jour des préférences utilisateur',
  'error.failed.to.update.coach.activation': 'Echec du changement de statut du coach',
  'error.on.get.companies': "Une erreur s'est produite lors de la récupération de la société",
  'error.failed.to.update.contextual.notification':
    'Echec de la mise à jour des préférences de notification contextuelle',
  'error.failed.to.update.navigateur.notification':
    'Echec de la mise à jour des préférences de notification du navigateur',

  'error.no.comment.to.post': 'Aucun commentaire à poster',
  'error.on.setting.timezone': "Une erreur s'est produite lors de la définition du fuseau horaire",

  'error.time.selection': "Le choix de l'heure doit être entre 8h et 18h",
  'error.failed.to.capture.screen': "Impossible de prendre la capture d'écran",

  'error.token.expired': 'Jeton expiré',
  'error.project.not.found': 'Projet introuvable',

  'error.on.setting.first.entry':
    "Une erreur s'est produite lors de la définition de la première entrée de l'utilisateur",
  'error.on.delete.admin.post':
    "Une erreur s'est produite lors de la suppression de cette publication",

  'error.on.mark.as.a3.process': "Une erreur s'est produite lors du marquage du processus A3",

  'error.on.set.company.director':
    "Une erreur s'est produite lors du paramétrage du directeur de la société",
  'error.leave.already.asked': 'Vous avez déjà demandé des congés dans cette plage de dates.',
  'error.label.update.professional.status':
    "Une erreur s'est produite lors de la mise à jour du statut professionnel",

  'error.create.objective': "Une erreur s'est produite lors de la création de l'objectif",
  'error.delete.objective': "Une erreur s'est produite lors de la suppression de l'objectif",

  'error.location.already.setted':
    'Vous avez déjà défini votre localisation dans cette plage de dates.',
  'error.location.set.error': 'Erreur lors de la définition de la localisation',
  'error.location.edit.error': 'Erreur lors de la modification de la localisation',
  'error.location.delete.error': 'Erreur lors de la suppréssion de la localisation',

  'error.event.already.asked': 'Vous avez déjà un événement dans cette plage de dates.',
  'error.start.time.required': "L'heure de début est requise lorsque l'heure de fin est spécifiée",
  'error.end.time.required': "L'heure de fin est requise lorsque l'heure de début est spécifiée",
  'error.time.required': 'Temps requis',

  'error.create.filter': 'Erreur lors de la création du filtre',
  'error.update.filter': 'Erreur lors de la mise à jour du filtre',
  'error.delete.filter': 'Erreur lors de la suppression du filtre',
  'error.allowed.characters':
    'Seuls les caractères alphanumériques et les tiret bas sont autorisés',
  'error.password.contain.name': 'Le mot de passe ne peut pas contenir le nom ou le prénom',
  'error.password.contain.date': 'Le mot de passe ne peut pas contenir de modèle de date',
  'error.token.invalid': 'Token invalide',
  'error.invitation.check': "Erreur lors la vérification de l'invitation",
  'error.invalid.invitation': 'Désolé, cette page est seulement accéssible depuis une invitation',
  'error.call.creation': 'Erreur lors de la création du call',
  'error.room.edition': 'Erreur lors de la modification de la salle',
  'error.room.deletion': 'Erreur lors de la fermeture de la salle',
  'error.room.has.been.closed': 'Cette salle a été fermée',
  'error.at.least.one.guest.or.team': 'Au moins un invité ou une équipe est requis',
  'error.cannot.access.this.one.to.one': "Impossible d'accéder à ce un à un",
  'error.phone.number': 'Numéro de téléphone invalide',
  'error.firebase.token.failed': "Erreur lors de la génération du token d'enregistrement firebase",
  'error.firebase.browser.not.support.service.worker':
    'Ce browser ne supporte pas le push notification.',
  'errors.deviceInfo': "Erreur en définissant les informations de l'appareil",
  'error.notification.denied':
    "Le push notifications n'est pas autorisé. Vérifiez la permission de votre navigateur",
};
export default error;
