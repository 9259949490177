import { gql } from '@apollo/client';
import {
  BADGE_FRAGMENT,
  COMMUNITY_BADGE_FRAGMENT,
  INDIVIDUAL_BADGE_FRAGMENT,
  USER_THANK_FRAGMENT,
} from './fragment';

export const ALL_PROFESSIONNAL_WEATHERS = gql`
  query AllProfessionnalWeathers {
    allProfessionnalWeathers {
      id
      title
      icon
    }
  }
`;

export const ALL_PROFESSIONAL_STATUSES = gql`
  query AllProfessionalStatuses {
    allProfessionalStatuses {
      id
      title
      icon
      order
    }
  }
`;
export const GET_EVENTS_TODAY = gql`
  query getEventsToday($limit: Int) {
    getEventsToday(limit: $limit) {
      id
      name
      description
      eventType {
        id
        name
        icon
        iconPublicUrl
        eventMessageSuggestions {
          id
          content
        }
      }
      companyMembers {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      day
    }
  }
`;

export const GET_MESSAGE_SUGGESTIONS = gql`
  query GetMessageSuggestions($type: MessageType, $limit: Int) {
    getMessageSuggestions(type: $type, limit: $limit) {
      id
      content
      type
    }
  }
`;

export const COWORKERS = gql`
  query Coworkers($companyId: ID) {
    coworkers(companyId: $companyId) {
      id
      email
      firstName
      lastName
      photoUrl
      photoPublicUrl
      role
      companyMembers {
        jobTitle
      }
    }
  }
`;

export const GET_ALL_EVENT = gql`
  query GetAllEvents {
    allEvents {
      id
      name
      eventType {
        id
        name
        icon
        iconPublicUrl
      }
    }
  }
`;

export const GET_ALL_EVENT_TYPES = gql`
  query GetAllEventTypes {
    allEventTypes {
      id
      name
      icon
      iconPublicUrl
      description
      events {
        id
        name
        companyMembers {
          id
          hiringDate
          userId
        }
        name
        description
        eventType {
          id
        }
        day
        removed
        updatedAt
        createdAt
      }
    }
  }
`;

export const CURRENT_COMPANY_LAST_LEADER_MESSAGE = gql`
  query CurrentCompanyLastLeaderMessage {
    currentCompanyLastLeaderMessage {
      id
      message
      member {
        id
        jobTitle
        user {
          id
          fullName
          photoPublicUrl
          photoUrl
          firstName
          lastName
        }
      }
    }
  }
`;

export const SEE_USER_THANKS = gql`
  query SeeThanks($id: ID!) {
    seeThanks(id: $id) {
      ...UserThankInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberReceivers {
        id
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
  ${USER_THANK_FRAGMENT}
`;
export const GET_CURRENT_PRO_WEATHER = gql`
  query CurrentProfessionnalWeather {
    currentCompanyMember {
      id
      currentProfessionnalWeather {
        id
        title
        icon
      }
    }
  }
`;

export const GET_CURRENT_PRO_STATUS = gql`
  query CurrentProfessionalStatus {
    currentCompanyMember {
      currentProfessionalStatus {
        title
        icon
        description
        order
      }
    }
  }
`;

export const GET_BADGES_BY_COMMUNITY = gql`
  query GetBadgesByCommunity($communityId: ID!) {
    badgesByCommunity(communityId: $communityId) {
      ...CommunityBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${COMMUNITY_BADGE_FRAGMENT}
  ${BADGE_FRAGMENT}
`;

export const GET_DAILY_DONES_COMMUNITY_BADGES = gql`
  query GetDailyDonesCommunityBadges {
    getDailyDonesCommunityBadges {
      ...CommunityBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${COMMUNITY_BADGE_FRAGMENT}
  ${BADGE_FRAGMENT}
`;

export const GET_BADGES_BY_COMPANY_MEMBER = gql`
  query GetBadgesByCompanyMember($companyMemberId: ID!) {
    getBadgesByCompanyMember(companyMemberId: $companyMemberId) {
      ...IndividualBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${INDIVIDUAL_BADGE_FRAGMENT}
  ${BADGE_FRAGMENT}
`;

export const GET_BADGES_BY_CATEGORY = gql`
  query GetBadgesByCategory($communityId: ID, $companyMemberId: ID) {
    getBadgesByCategory(communityId: $communityId, companyMemberId: $companyMemberId) {
      community {
        id
        name
        logoPublicUrl
        mission
        myCommunityRole
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
      achivements {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      special {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      pointsCollection {
        badgeId
        badge {
          ...BadgeFragment
          customBadge {
            minExp
            maxExp
          }
        }
        status
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const GET_DAILY_DONES_BADGES_BY_CATEGORY = gql`
  query GetDailyDonesBadgesByCategory {
    getDailyDonesBadgesByCategory {
      achivements {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      special {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      pointsCollection {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const GET_EARNED_EXP_EVENTS = gql`
  query GetEarnedExpEvents {
    earnedExpEvents {
      id
      expEvent {
        id
        exp
      }
      earnedAt
    }
  }
`;

export const GET_DAILY_EXP = gql`
  query GetDailyExp {
    dailyExp {
      value
      date
    }
  }
`;

export const GET_TODAYS_EARNED_EXP = gql`
  query GetTodaysEarnedExp {
    todaysEarnedExp {
      exp
      earnedExp {
        id
        description
        earnedAt
      }
    }
  }
`;

export const GET_CUSTOM_BADGES = gql`
  query GetCustomBadges {
    allCustomBadges {
      badgeId
      companyId
      minExp
      maxExp
      prevMinExp
      nextMaxExp
      badge {
        id
        name
        iconPublicUrl
        type
        minExp
        maxExp
      }
    }
  }
`;

export const GET_ALL_EXP_EVENTS = gql`
  query GetAllExpEvents(
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
    $filter: ExpEventFilter
  ) {
    expEvents: allExpEvents(
      page: $page
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      filter: $filter
    ) {
      id
      exp
      communityExp
      genericExpEvent {
        id
        name
        description
        expEventType
      }
    }
    total: _allExpEventsMeta(filter: $filter) {
      count
    }
  }
`;

export const GET_EXP_EVENT = gql`
  query GetExpEvent($id: ID!) {
    expEvent: ExpEvent(id: $id) {
      id
      exp
      communityExp
      genericExpEvent {
        id
        name
        description
        expEventType
      }
    }
  }
`;
