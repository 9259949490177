import { gql } from '@apollo/client';
import { COMMENT_IN_ACTION_FRAGMENT } from 'graphql/comment/fragment';
import { USER_ON_POST_FRAGMENT } from 'graphql/user/fragment';
import {
  ACTION_FRAGMENT,
  COMMUNITY_FRAGMENT,
  COMMUNITY_MEMBERS_USER_FRAGMENT,
  SIMPLE_COMMUNITY_FRAGMENT,
} from './fragment';

export const GET_MY_COMMUNITY = gql`
  query GetMyCommunity {
    me {
      id
      myCommunity {
        ...Community
      }
    }
  }
  ${COMMUNITY_FRAGMENT}
`;

export const GET_COMMUNITY = gql`
  query GetCommunity($communityId: ID!) {
    Community(id: $communityId) {
      id
      mission
      rommance
      members {
        id
        user {
          ...UserOnPostInfo
        }
        companyMemberId
        companyMember {
          id
          currentMotto {
            id
            content
          }
          currentProfessionnalWeather {
            id
            title
            icon
          }
        }
      }
      memberCount
      memberRequestCount
    }
  }
  ${USER_ON_POST_FRAGMENT}
`;

export const GET_COMMUNITY_BY_ID = gql`
  query Community($communityId: ID!) {
    community: Community(id: $communityId) {
      ...SimpleCommunityInfo
    }
  }
  ${SIMPLE_COMMUNITY_FRAGMENT}
`;

export const GET_COMMUNITY_ACTIONS = gql`
  query GetCommunityActions($id: ID!, $orderBy: String) {
    Community(id: $id) {
      id
      actions(orderBy: $orderBy) {
        ...ActionInfo
        responsibles {
          id
          user {
            ...UserOnPostInfo
          }
        }
        indicator {
          id
        }
        createdByCompanyMember {
          id
          user {
            ...UserOnPostInfo
          }
        }
        post {
          id
        }
        project {
          id
        }
      }
    }
  }
  ${ACTION_FRAGMENT}
  ${USER_ON_POST_FRAGMENT}
`;

export const CURRENT_COMMUNITY = gql`
  query CurrentCommunity {
    currentCommunity {
      id
      name
      slug
      logo
      logoPublicUrl
    }
  }
`;

export const GET_ACTIONS_BY_POST_ID = gql`
  query GetActionsByPostId($id: ID!) {
    getActionsByPostId(id: $id) {
      id
      title
      description
      targetDate
      isCompleted
      responsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      createdAt
    }
  }
  ${USER_ON_POST_FRAGMENT}
`;

export const GET_ALL_ACTIONS = gql`
  query GetAllActions(
    $postId: ID
    $projectId: ID
    $filter: ActionFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
    $targedDate: DateRange
  ) {
    actions: allActions(
      postId: $postId
      projectId: $projectId
      filter: $filter
      page: $page
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      targedDate: $targedDate
    ) {
      ...ActionInfo

      createdBy {
        ...UserOnPostInfo
      }
      createdByCompanyMember {
        id
        user {
          ...UserOnPostInfo
        }
      }
      responsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      indicator {
        id
      }
      community {
        ...Community
      }
    }
    total: _allActionsMeta(postId: $postId, projectId: $projectId, filter: $filter) {
      count
    }
  }
  ${ACTION_FRAGMENT}
  ${USER_ON_POST_FRAGMENT}
  ${COMMUNITY_FRAGMENT}
`;

export const GET_COMMUNITY_MEMBERS = gql`
  query GetCommunityMembers($id: ID!) {
    Community(id: $id) {
      id
      name
      members {
        id
        role
        companyMember {
          id
          currentProfessionnalWeather {
            id
            title
            icon
          }
          user {
            ...UserOnPostInfo
          }
        }
      }
    }
  }
  ${USER_ON_POST_FRAGMENT}
`;

export const GET_COMMUNITY_BY_COMPANY_ID = gql`
  query GetCommunityByCompanyId(
    $companyId: ID
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CommunityFilter
  ) {
    getCommunityByCompanyId(
      companyId: $companyId
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      slug
      logo
      logoPublicUrl
      mission
      rommance
      indicators {
        id
        name
        value
        level
        description
        date
        trend
        createdAt
      }
      problems {
        id
        title
        who
        what
        how
        where
        when
        impact
        problemCategory {
          title
        }
        problemLevel
        demand
        communities {
          id
        }
        experts {
          id
        }
        companyMemberExperts {
          id
        }
        createdAt
      }
      actions {
        id
        description
        targetDate
        responsibles {
          id
        }
        actionType
        createdAt
      }
      projects {
        id
      }
      members {
        id
        companyMemberId
        companyMember {
          id
          currentProfessionnalWeather {
            id
            title
            icon
          }
          user {
            ...CommunityMembersUserInfo
          }
        }
        role
      }
    }
    currentWholeCompany {
      id
      slug
    }
  }
  ${COMMUNITY_MEMBERS_USER_FRAGMENT}
`;

export const GET_MEMBERS_BY_COMMUNITY_ID = gql`
  query GetMembersByCommunityId($communityId: ID!) {
    community: Community(id: $communityId) {
      id
      name
      members {
        id
        role
        companyMember {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
          currentProfessionnalWeather {
            id
            title
            icon
          }
        }
      }
    }
  }
`;

export const GET_COMMUNITY_ARCHIVES = gql`
  query GetCommunityArchives($id: ID!) {
    community: Community(id: $id) {
      id
      archives {
        id
        name
        path
        publicUrl
        createdAt
      }
    }
  }
`;

export const GET_COMMUNITY_MEMBERS_ROLE_BY_SELECTED_COMMUNITY = gql`
  query getCommunityMembersRoleBySelectedCommunity($teamId: ID!) {
    communityRole: getCommunityMembersRoleBySelectedCommunity(teamId: $teamId)
  }
`;

export const GET_COMMUNITIES_BY_COMPANY_MEMBER = gql`
  query getCommunitiesByCompanyMember($companyMemberId: ID, $orderBy: String) {
    communitiesByCompanyMember: getCommunitiesByCompanyMember(
      input: { companyMemberId: $companyMemberId, orderBy: $orderBy }
    ) {
      myCommunities {
        id
        name
        logoPublicUrl
      }
      otherCommunities {
        id
        name
        logoPublicUrl
      }
    }
    companyMember: getCompanyMemberById(id: $companyMemberId) {
      id
      role
      userAccess
      user {
        id
        fullName
      }
    }
  }
`;
export const GET_ACTION_BY_ID = gql`
  query getActionById($actionId: ID!) {
    action: actionById(id: $actionId) {
      ...ActionInfo
      createdBy {
        ...UserOnPostInfo
      }
      createdByCompanyMember {
        id
        user {
          ...UserOnPostInfo
        }
      }
      responsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      indicator {
        id
      }
      community {
        ...Community
      }

      comments {
        ...CommentInActionFragment
      }
    }
  }

  ${ACTION_FRAGMENT}
  ${USER_ON_POST_FRAGMENT}
  ${COMMUNITY_FRAGMENT}

  ${COMMENT_IN_ACTION_FRAGMENT}
`;
