const error: Record<string, string> = {
  'error.company.slug.not.found': 'Company slug not found',
  'something.went.wrong': 'Something went wrong',
  'error.create.idea': 'Create idea error',
  'error.signup': 'Signup error',
  'error.community.update': 'An error occurred while updating team',
  'error.community.create': 'An error occurred while creating team',
  'error.community.delete': 'An error occurred while deleting team',
  'error.community.delete.user': 'An error occurred while deleting member on team',
  'error.community.update.user.role': 'An error occurred while updating team member role',
  'error.create.indicator': 'An error occurred while creating indicator',
  'error.update.indicator': 'An error occurred while updating indicator',
  'error.delete.indicator': 'An error occurred while deleting indicator',
  'error.action.delete': 'An error occurred while deleting action',
  'error.post.delete': 'An error occurred while deleting action',
  'error.action.update': 'An error occurred while updating action',
  'error.action.create': 'An error occurred while creating action',
  'error.community.actions': 'An error occurred while getting team actions',
  'error.user.not.found': 'An error occurred while getting User',
  'error.create.project': 'An error occurred while creating Project',
  'error.edit.project': 'An error occurred while editing Project',
  'error.convert.idea': 'An error occurred while converting Idea',
  'error.convert.post': 'An error occurred while converting Post',
  'error.empty.community': 'You have no team',
  'error.failed.to.fetch.company.products': 'Failed to fetch company products',
  'error.failed.to.create.order': 'Failed to create order',
  'error.failed.to.update.my.total.coins': 'Failed to update total coins',
  'error.failed.to.fetch.community.events': 'Failed to fetch team events for the selected team',
  'error.failed.to.fetch.team.event': 'Failed to fetch the team event',
  'error.failed.to.fetch.meeting.events': 'Failed to fetch meeting events',
  'error.must.have.community': 'Must have team',
  'error.must.have.expert': 'Must have expert',
  'error.must.have.responsible': 'Must have responsible',
  'error.minimum.2022': 'Minimum 2022',
  'error.minimum.today': 'Minimum today',
  'error.minimum.tomorrow': 'Minimum tomorrow',
  'error.maximum.number.of.character':
    '{item} is too long (the maximum is {maxNbrOfChars} characters)',
  'error.failed.to.get.problems.count': 'Failed to get problems count between team ',
  'error.failed.to.share.post': 'Failed to share post or the post is already shared to this team',
  'error.profile.not.found': 'Profile not found',
  'error.save.personal.information': 'An error occurred while saving personal information',
  'error.delete.picture': 'An error occurred while deleting picture',
  'error.delete.file': 'An error occurred while deleting file',
  'error.falided.to.create.counter.measure': 'Failed to create  proposal countermeasure ',
  'error.failed.to.create.counter.measure': 'Failed to create  proposal countermeasure ',
  'error.failed.to.update.counter.measure': 'Failed to update  proposal countermeasure ',
  'error.failed.to.delete.counter.measure': 'Failed to delete  proposal countermeasure ',
  'error.failed.to.delete.goal': 'Failed to delete  indicator ',
  'error.failed.to.change.post.a3.status': 'Failed to change post status ',
  'error.failed.to.save.shared.horizon': 'Failed to save our Shared Horizon',
  'error.failed.to.update.shared.horizon': 'Failed to modify our Shared Horizon',
  'error.failed.to.delete.measure.file': 'Failed to delete measure file',
  'error.failed.to.fetch.company.process': 'Failed to fetch company process',
  'error.incorrect.password': 'Incorrect password',
  'error.code.is.required': 'The code is required',
  'error.code.is.incorrect': 'The code is ion updatencorrect',
  'error.code.is.expired': 'The code is expired',
  'error.team.is.required': 'Team is required',
  'error.supervisor.is.required': 'Supervisor is required',
  'user.change.supervisor.error': 'Failed to update Supervisor',
  'error.current.company': 'An error occurred while getting current company',
  'error.switch.company': 'An error occurred while switching company',
  'error.file.is.larger.than.undred.mb': 'File is larger than 100Mb',
  'error.file.invalid.type': 'Invalid file type',
  'error.some.files.are.invalid': 'Some files are invalid',
  'error.file.too.many.files': 'Too many files',
  'error.file.is.larget.than.max.size': 'File is larger than {maxSize}Mb',
  'error.invalid.date': 'Invalid date',

  // Meeting
  'error.meeting.dialog.error.attendees': 'Please specify at least one attendee',
  'error.failed.to.create.meeting': 'Failed to create Meeting',
  'error.failed.to.update.meeting': 'Failed to update Meeting',
  'error.failed.to.fetch.meeting': 'Failed to fetch Meeting ',
  'error.failed.to.fetch.registrant': 'Failed to fetch Meeting registrants ',
  'error.failed.to.accept.meeting': 'Failed to accept zoom meeting status',
  'error.failed.to.deny.meeting': 'Failed to deny zoom meeting status',

  // User preference
  'error.failed.to.update.preference': 'Failed to update user preference',
  'error.failed.to.update.coach.activation': 'Failed to change the coach activation status',
  'error.on.get.companies': 'An error occurred while getting companies',
  'error.failed.to.update.contextual.notification':
    'Failed to change the contextual notification status',
  'error.failed.to.update.navigateur.notification':
    'Failed to change the navigator notification status',

  'error.no.comment.to.post': 'No comment to post',
  'error.on.setting.timezone': 'An error occurred while setting timezone',

  'error.time.selection': 'Time selection must be between 8a.m and 6p.m',
  'error.failed.to.capture.screen': 'Failed to take screen capture',

  'error.token.expired': 'Token expired',
  'error.project.not.found': 'Project not found',
  'error.files.download.issues': 'An error occurred while downloading file',
  'error.on.setting.first.entry': "An error occured while setting user's first entry",
  'error.on.delete.admin.post': 'An error occurred while deleting post',

  'error.on.mark.as.a3.process': 'An error occurred while marking as A3 process',

  'error.on.set.company.director': 'An error occured while setting company director',
  'error.leave.already.asked': 'You have already asked for leaves in that date range.',
  'error.label.update.professional.status': 'An error occured while updating Professional Status',

  'error.create.objective': 'An error occurred while creating objective',
  'error.delete.objective': 'An error occurred while creating objective',

  'error.location.already.setted': 'You have already set location in that date range.',
  'error.location.set.error': 'Error while setting location',
  'error.location.edit.error': 'Error while editing location',
  'error.location.delete.error': 'Error while deleting location',

  'error.event.already.asked': 'You have already an event in that date range.',
  'error.start.time.required': 'Start time is required when end time is specified',
  'error.end.time.required': 'End time is required when start time is specified',
  'error.time.required': 'Time is required',

  'error.create.filter': 'Error while creating filter',
  'error.update.filter': 'Error while updating filter',
  'error.delete.filter': 'Error while deleting filter',
  'error.allowed.characters': 'Only alphanumeric characters and underscores are allowed',
  'error.password.contain.name': 'Password cannot contain firstname or lastname',
  'error.password.contain.date': 'Password cannot contain a date pattern',
  'error.token.invalid': 'Invalid token',
  'error.invitation.check': 'Error while checking invitation',
  'error.invalid.invitation': 'Sorry, this page is only accessible from invitation',
  'error.call.creation': 'Error while creating call',
  'error.room.edition': 'Error while editing room',
  'error.room.deletion': 'Error while closing room',
  'error.room.has.been.closed': 'This room has been closed',
  'error.at.least.one.guest.or.team': 'At least one guest or team is required',
  'error.cannot.access.this.one.to.one': 'Cannot access this one-to-one',
  'error.phone.number': 'Invalid phone number',
  'error.firebase.token.failed': 'Error on generate firebase registration token',
  'error.firebase.browser.not.support.service.worker':
    'Push notifications not supported by this browser.',
  'errors.deviceInfo': 'Error on set device info',
  'error.notification.denied':
    'Push notifications not allowed. You need to check the browser permissions',
};
export default error;
