import React, { createContext, FC, useState } from 'react';
import moment from 'moment-timezone';
import { TCalendarNavigation } from 'components/scenes/Calendar/DnDCalendar/type';
import { CalendarDateRangeInput } from 'graphql/generated/graphql';

const initialDate = moment().toISOString();

const initialNavigationVariables: TCalendarNavigation = {
  date: initialDate,
  view: 'week',
};

const CalendarNavigationContext = createContext<{
  navigationVariables: TCalendarNavigation;
  traveledDateRange?: CalendarDateRangeInput;
  handleChangeNavigationVariables: (
    newNavigationVariables: Partial<Pick<TCalendarNavigation, 'date' | 'view'>>,
  ) => void;
  resetDateRange: () => void;
}>({
  navigationVariables: initialNavigationVariables,
  handleChangeNavigationVariables: () => {},
  resetDateRange: () => {},
});

export const CalendarNavigationProvider: FC = ({ children }) => {
  const [navigationVariables, setNavigationVariables] = useState<TCalendarNavigation>(
    initialNavigationVariables,
  );

  const [traveledDateRange, setTraveledDateRange] = useState<CalendarDateRangeInput | undefined>();

  const resetDateRange = () => setTraveledDateRange(undefined);

  const handleChangeNavigationVariables = (
    newNavigationVariables: Partial<TCalendarNavigation> = {},
  ) => {
    const { date: newDate, view: newView } = newNavigationVariables;

    setNavigationVariables((prevNavigationVariables) => {
      const view = newView ?? prevNavigationVariables.view;

      if (newDate) {
        const incomingNextOrPreviousDateMoment = moment(newDate);
        const incomingNextOrPreviousDateToISOString =
          incomingNextOrPreviousDateMoment.toISOString();

        setTraveledDateRange((prevTraveledDateRange) => {
          // in case of previsou traveled date range is not setted
          if (!prevTraveledDateRange) {
            // check new date if next or previous
            const isNext = incomingNextOrPreviousDateMoment.isAfter(
              moment(prevNavigationVariables.date),
              'd',
            );
            return isNext
              ? { start: prevNavigationVariables.date, end: incomingNextOrPreviousDateToISOString }
              : { start: incomingNextOrPreviousDateToISOString, end: prevNavigationVariables.date };
          }

          let { start, end } = prevTraveledDateRange;
          if (incomingNextOrPreviousDateMoment.isBefore(moment(start), 'd'))
            return { start: incomingNextOrPreviousDateToISOString, end };
          if (incomingNextOrPreviousDateMoment.isAfter(moment(end), 'd'))
            return { start, end: incomingNextOrPreviousDateToISOString };

          // return prev to avoid fetch more if date range is not changes
          return prevTraveledDateRange;
        });

        return { date: incomingNextOrPreviousDateToISOString, view };
      }

      return { ...prevNavigationVariables, view };
    });
  };

  return (
    <CalendarNavigationContext.Provider
      value={{
        navigationVariables,
        traveledDateRange,
        handleChangeNavigationVariables,
        resetDateRange,
      }}
    >
      {children}
    </CalendarNavigationContext.Provider>
  );
};

export default CalendarNavigationContext;
